<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <div>
          <h1>ENTER BID</h1>
        </div>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <customer-info />

    <v-row>
      <v-col cols="12" md="2" sm="4">
        <div>
          <v-btn color="primary" dark>LINK TO DESKTOP BID</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-select
          label="Division"
          v-model="bid_info.division_id"
          :items="divsion_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Probability"
          v-model="bid_info.probability"
          :items="probability_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-checkbox
          class="mt-5"
          v-model="bid_info.initial_bid"
          label="Initial"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Terms"
          v-model="bid_info.terms"
          :items="terms_values"
        ></v-select>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="text-right">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="reset = false"
          >
            Add
          </v-btn>
        </div>
      </template>

      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click.native="dialog = false">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Enter Bid</h6>
              </div>

              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="pr-10 pl-10">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Item"
                              :rules="[(v) => !!v || 'This field is required']"
                              v-model="enterBid.Item"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Qty"
                              :rules="[(v) => !!v || 'This field is required']"
                              v-model="enterBid.Qty"
                              min="1"
                              @input="
                                enterBid.ext_Price = enterBid.Qty * enterBid.pr
                              "
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-textarea
                              label="Comments"
                              v-model="enterBid.comments"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Pr"
                              :rules="[(v) => !!v || 'This field is required']"
                              v-model="enterBid.pr"
                              min="1"
                              @input="
                                enterBid.ext_Price = enterBid.Qty * enterBid.pr
                              "
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Ext price"
                              :rules="[(v) => !!v || 'This field is required']"
                              v-model="enterBid.ext_Price"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                Add
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <datatable
      :headers="select_from_open_slots.headers"
      :staticData="select_from_open_slots.data"
      :componentURL="componentURL"
    ></datatable>

    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-textarea
          label="Comments:"
          auto-grow
          outlined
          rows="3"
          row-height="40"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          label="Discount"
          v-model="bid_info.discount_pct"
          :items="discount_values"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn color="primary" @click="$router.push({ name: 'AddPDF' })" block
            >Add PDF
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn
            color="primary"
            @click="$router.push({ name: 'AddImages' })"
            block
            >Add Images
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn
            color="primary"
            @click="$router.push({ name: 'ReviewWarranty' })"
            block
            >Review Warranty
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn
            color="primary"
            @click="$router.push({ name: 'EditBidPack' })"
            block
            >Edit Bid Pack
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn color="primary" block>Preview</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn color="primary" block>SAVE wo SENDING</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="6">
        <div>
          <v-btn color="primary" block>SEND BID</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  components: { CustomerInfo, Datatable },
  name: "EnterBid.vue",
  data() {
    return {
      valid: false,
      dialog: false,
      componentURL: "",
      probability_values: [
        "0%",
        "10%",
        "20%",
        "30%",
        "40%",
        "50%",
        "60%",
        "70%",
        "80%",
        "90%",
        "100%",
      ],
      terms_values: ["Default"],
      discount_values: ["Default"],
      divsion_values: ["12"],
      bid_info: {
        probability: "",
        terms: "Default",
        discount_pct: "Default",
        division_id: "12",
        Initial_bid: false,
        bid_comments: "",
      },
      select_from_open_slots: {
        headers: [
          { text: "Item", value: "Item" },
          { text: "Qty", value: "Qty" },
          { text: "Comments", value: "comments" },
          { text: "Pr", value: "pr" },
          { text: "Ext Price", value: "ext_Price" },
        ],
        data: [
          {
            Item: "Any Item",
            Qty: 1,
            comments: "comments on item",
            pr: 10,
            ext_Price: 10,
          },
          {
            Item: "Any Item2 ",
            Qty: 2,
            comments: "comments on item",
            pr: 10,
            ext_Price: 20,
          },
          {
            Item: "Any Item 312",
            Qty: 3,
            comments: "comments on item",
            pr: 30,
            ext_Price: 90,
          },
        ],
      },
      enterBid: {
        Item: "",
        Qty: 1,
        comments: "",
        pr: 1,
        ext_Price: 1,
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.select_from_open_slots.data.push(this.enterBid);
        this.dialog = false;
        this.enterBid = {
          Item: "",
          Qty: "",
          comments: "",
          pr: "",
          ext_Price: "",
        };
      }
    },
  },
};
</script>

<style scoped></style>
